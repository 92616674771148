.hero-admin {
  background-color: #f5f5f5;
  color: #808080;
}
.input-admin,
.select-admin {
  border-color: #808080;
}
.lesson h2 {
  color: #1c1c1c;
}
.btn-admin {
  color: #0d6efd;
  border: 1px solid #0d6efd;
}
