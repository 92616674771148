@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Inter', sans-serif;
}

.bg-auth {
  background: conic-gradient(
    from 142.8deg at 58.75% 50%,
    #44bf93 -56.25deg,
    #4bca84 37.5deg,
    #0d6efd 191.25deg,
    #44bf93 303.75deg,
    #4bca84 397.5deg
  );
}

.bg-auth-img {
  background: url('./assets/images/auth-image.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-admin {
  background-color: #f5f5f5;
  color: #808080;
}
.input-admin,
.select-admin {
  border-color: #808080;
}
.lesson h2 {
  color: #1c1c1c;
}
.btn-admin {
  color: #0d6efd;
  border: 1px solid #0d6efd;
}

.ck .ck-editor__main .ck-editor__editable {
  height: 350px !important;
  border-radius: 8px;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
