@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");

* {
  font-family: "Inter", sans-serif;
}

html {
  background-color: #f5f5f5;
}

.classroom {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #585858;
}

.filter {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #585858;
}

.select_btn {
  width: 160px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #808080;
  border-radius: 8px;
}

.select_options {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #303030;
  width: 136px;
  height: 24px;
}

.createnewimg {
  background-color: #0d6efd;
}

.createnewtext {
  letter-spacing: 0.1px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background-color: #0d6efd;
  border-radius: 8px;
}

.stage1 {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #1c1c1c;
  background-color: #ffffff;
}

.lesson1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.1px;
  color: #0d6efd;
  background-color: #ffffff;
}

.stage3 {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.nolesson {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.stage3bg {
  background: #e4e4e4;
}
