@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");

* {
  font-family: "Inter", sans-serif;
}

.col1_bg {
  background: conic-gradient(
    from 142.8deg at 58.75% 50%,
    #44bf93 -56.25deg,
    #4bca84 37.5deg,
    #0d6efd 191.25deg,
    #44bf93 303.75deg,
    #4bca84 397.5deg
  );
  color: #f5f5f5;
}

.col2_bg {
  background: #ffffff;
}

.logo_shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.box_shadow {
  box-shadow: 15px 15px 24px 24px rgba(22, 51, 102, 0.25);
}

.b_radRight {
  border-radius: 0px 24px 24px 0px;
}

.b_radLeft {
  border-radius: 24px 0px 0px 24px;
}

.col1_heading {
  font-weight: 700;
  font-size: 45px;
  line-height: 52px;
}

.col1_para {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.col2_text {
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: #303030;
}

.login_btn {
  background: #0d6efd;
  border-radius: 8px;
  cursor: pointer;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.login_link {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #0d6efd;
}

.login_text {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #808080;
}

::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #808080;
}
