@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
.wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
}
.conic-bg {
  background: conic-gradient(
    from 142.8deg at 58.75% 50%,
    #44bf93 -56.25deg,
    #4bca84 37.5deg,
    #0d6efd 191.25deg,
    #44bf93 303.75deg,
    #4bca84 397.5deg
  );
  width: 50%;
  height: 100vh;
  position: relative;
}
.ash-bg {
  background: #f5f5f5;
  width: 50%;
  height: 100vh;
  position: relative;
}
.bg-trans {
  height: 95%;
  width: 90%;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  box-shadow: -15px 15px 24px rgba(22, 51, 102, 0.25);
  backdrop-filter: blur(8px);
  border-radius: 24px 0px 0px 24px;
  background: linear-gradient(
    132.75deg,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
}
.bg-form {
  height: 95%;
  width: 90%;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background: #ffffff;
  box-shadow: 15px 15px 24px rgba(22, 51, 102, 0.25);
  border-radius: 0px 24px 24px 0px;
}
.inner {
  margin: auto;
}
form input,
form button {
  height: 54px;
  border-radius: 8px;
}
@media only screen and (max-width: 500px) {
  .wrapper {
    flex-direction: column;
  }
  .ash-bg,
  .conic-bg {
    width: 100%;
  }
  .bg-trans,
  .bg-form {
    position: relative;
    width: 100%;
    padding: 0 6px;
  }

  .bg-form {
    border-radius: 0;
    box-shadow: none;
    height: 100%;
  }
}
